export const getFileNameExtension = (fileName: string): string => {
    if (!fileName) {
        return '';
    }
    // get file extension from file name eg file.pdf?extracted=3 should be file.pdf
    const fileExtension = fileName.split('?')[0].split('.').pop();
    if (!fileExtension) {
        return 'doc';
    }
    return fileExtension.toLowerCase();
};