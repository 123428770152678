import Button from 'components/dist/atoms/Button';
import Checkbox from 'components/dist/atoms/Checkbox';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Label from 'components/dist/atoms/Label';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import FileIcon, { getFileNameExtension } from 'components/dist/molecules/FileIcon';
import { ShoeboxItemResponseDto } from 'src/backend';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { getFileNameWithoutExtension } from 'src/utils';
import { getFilePath } from 'src/utils/file/get-file-path';
import { getFilePathFolders } from 'src/utils/file/get-file-path-folders';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';

const UN_MERGABLE_EXTENSIONS = ['xls', 'xlsx', 'xlsm'];

interface MergeFilesDialogProps {
    onOpenChange: (open: boolean) => void;
    onMergeConfirmClick: (type: "WITH_EXISTING" | "REPLACE_EXISTING") => void;
    onSelectAllCheckedChange: (checked: boolean) => void;
    onMergeFileCheckedChange: (checked: boolean, index: number) => void;
    element: FormElementV2ResponseDtoExtended | ShoeboxItemResponseDto;
    hasFilesNeedsConvertToPdf: boolean;
    checkedFiles: File[];
    files: File[]
    loading: boolean;
    checkAllChecked: boolean | 'indeterminate';
}

export const MergeFilesDialog = (props: MergeFilesDialogProps) => {
    const groupedFiles = getTopLevelFolderOrFiles(props.files);
    const checkedFilesPaths = props.checkedFiles.map(getFilePath);

    const onCheckedChange = (file: File, checked: boolean, index: number) => {
        if (file.name.endsWith('.folder')) {
            // If the file is a folder, we need to check all files inside the folder
            const folderPath = getFileTopLevelFolder(file);
            const filesInsideFolder = props.files.filter(file => getFilePath(file).startsWith(`/${folderPath}/`));
            filesInsideFolder.forEach(file => props.onMergeFileCheckedChange(checked, props.files.indexOf(file)));
        } else {
            props.onMergeFileCheckedChange(checked, index);
        }
    }

    return <Dialog
        open
        onOpenChange={props.onOpenChange}>
        <Dialog.Content
            overlayClassName='z-full-screen'
            className='divide-y divide-gray-neutral-80 gap-0 z-full-screen'>
            <Dialog.Title>
                Upload to {props.element.title}
            </Dialog.Title>
            <Stack className='px-4 pt-2 pb-4' space="md">
                <Text size="sm">
                    An item already exists, do you want to replace or merge uploaded item with this item{props.checkedFiles.length > 1 ? "s" : ""} ?
                </Text>
                <Separator />
                {props.files.length > 1 && <Label className='flex items-center gap-2'>
                    <Checkbox
                        onCheckedChange={(checked: boolean) => props.onSelectAllCheckedChange(checked)}
                        checked={props.checkAllChecked}
                        size="sm" />
                    Select All
                </Label>}
                {props.hasFilesNeedsConvertToPdf && <Stack row space="sm" items="center">
                    <Icon name="InfoEmpty" className='text-gray-neutral-70' width={14} height={14} strokeWidth={1.5} />
                    <Text size="xs" variant="secondary">
                        Files will be converted to pdf if merged
                    </Text>
                </Stack>}
                <Stack space="md" className='h-40 overflow-y-scroll scrollbar-stable pl-4'>
                    {groupedFiles.map((file, index) => (
                        <Stack
                            space="sm" items="center" row key={getFilePath(file)}>
                            {props.files.length > 1 && <Checkbox
                                onCheckedChange={(checked: boolean) => onCheckedChange(file, checked, index)}
                                checked={checkedFilesPaths.includes(getFilePath(file))}
                                size="sm" />}
                            <FileIcon fileName={file.name} />
                            <Text size="sm" truncate>{getExtensionFromFilename(file.name) === 'folder' ? getFileNameWithoutExtension(file.name) : file.name}</Text>
                        </Stack>))}
                </Stack>
            </Stack>
            <Dialog.Footer className='flex-row sm:justify-between'>
                <Dialog.Close asChild>
                    <Button
                        disabled={props.loading}
                        variant="outline">
                        Cancel
                    </Button>
                </Dialog.Close>
                <Stack
                    row
                    space="md"
                    justify='end' className='flex-1'>
                    <Button
                        loading={props.loading}
                        disabled={props.checkedFiles.length === 0}
                        onClick={() => props.onMergeConfirmClick('REPLACE_EXISTING')}

                        variant="outline"
                        className='text-blue-100'>
                        Replace
                    </Button>
                    <Button
                        disabled={props.checkedFiles.length === 0 ||
                            ('answer' in props.element && UN_MERGABLE_EXTENSIONS.includes(getFileNameExtension(props.element.answer.document.name))) ||
                            props.checkedFiles.some(file => UN_MERGABLE_EXTENSIONS.includes(getFileNameExtension(file.name)))
                        }
                        loading={props.loading}
                        onClick={() => props.onMergeConfirmClick('WITH_EXISTING')}
                    >
                        Merge
                    </Button>
                </Stack>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>
};

const getFileTopLevelFolder = (file: File): string => {
    const filePathFolders = getFilePathFolders(file);
    return filePathFolders.length > 0 ? filePathFolders[0] : '';
}

const getTopLevelFolderOrFiles = (files: File[]): File[] => {
    // Create a map to track the top-level folders and files
    const topLevelFilesMap = new Map<string, File>();

    // Process each file path
    files.forEach(file => {
        // Split the file path by "/"
        const topLevelFolder = getFileTopLevelFolder(file);
        if (!topLevelFolder) {
            // If there is no parent folder, it's just a file, so we add it directly
            topLevelFilesMap.set(file.name, file);
            // Otherwise, we add the top-level folder as a "file"
            // Check if the folder is not already added
        } else if (!topLevelFilesMap.has(topLevelFolder)) {
            // Create a new `File` object to represent the folder
            topLevelFilesMap.set(topLevelFolder, {
                ...file,
                name: `${topLevelFolder}.folder`,
            });
        }
    });

    // Convert the map to an array and return the list of top-level files
    return Array.from(topLevelFilesMap.values());
}