import { j as s } from "./jsx-runtime-B6kdoens.js";
import { $ as m, a as p, b as f, c as x, d as b, e as z, f as T, g as $ } from "./index-BxpO1vOn.js";
import * as c from "react";
import { c as o } from "./utils-CJ9afRe1.js";
const v = T, g = m;
g.displayName = m.displayName;
const l = ({ ...a }) => /* @__PURE__ */ s.jsx($, { ...a });
l.displayName = $.displayName;
const u = c.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ s.jsx(
  p,
  {
    ref: t,
    className: o(
      "fixed inset-0 z-dialog bg-black-primary/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      a
    ),
    ...e
  }
));
u.displayName = p.displayName;
const y = c.forwardRef(({ className: a, children: e, overlayClassName: t, withoutPortal: h, ...k }, D) => {
  const r = /* @__PURE__ */ s.jsx(u, { className: t, onWheel: (d) => d.stopPropagation(), children: /* @__PURE__ */ s.jsx(
    f,
    {
      onInteractOutside: (d) => {
        var i, n;
        (((i = d.target) == null ? void 0 : i.closest("[role='alert']")) ?? ((n = d.target) == null ? void 0 : n.closest("[data-toast='true']"))) && d.preventDefault();
      },
      ref: D,
      className: o(
        "fixed sm:max-h-[95%] left-[50%] top-[50%] z-dialog flex flex-col w-full sm:max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
        a
      ),
      ...k,
      children: e
    }
  ) });
  return h ? r : /* @__PURE__ */ s.jsx(l, { children: r });
});
y.displayName = f.displayName;
const N = ({
  className: a,
  ...e
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: o(
      "flex flex-col py-3 px-4 text-center sm:text-left",
      a
    ),
    ...e
  }
);
N.displayName = "Header";
const j = ({
  className: a,
  hideDivider: e,
  ...t
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: o(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 px-3 py-2",
      {
        "border-t": !e
      },
      a
    ),
    ...t
  }
);
j.displayName = "Footer";
const w = c.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ s.jsx(
  x,
  {
    ref: t,
    className: o(
      "text-black- py-2 px-3.5 text-sm font-medium leading-none tracking-tight",
      a
    ),
    ...e
  }
));
w.displayName = x.displayName;
const R = c.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ s.jsx(
  b,
  {
    ref: t,
    className: o("px-3", a),
    ...e
  }
));
R.displayName = b.displayName;
const H = Object.assign(v, {
  Content: y,
  Description: R,
  Footer: j,
  Header: N,
  Title: w,
  Trigger: g,
  Portal: l,
  Close: z
});
export {
  H as D
};
