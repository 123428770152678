import { useGetLicenseKeysQuery } from "src/services/appApi";

interface ReturnValue {
    googleMapsKey: string;
    pdftronKey: string;
    syncfusionKey: string;
}

export const useLicenseKeys = (): ReturnValue => {
    const { data } = useGetLicenseKeysQuery();
    return {
        googleMapsKey: data?.googleMapsKey,
        pdftronKey: data?.pdftronKey,
        syncfusionKey: data?.syncfusionKey,
    }
};