import { j as x } from "./jsx-runtime-B6kdoens.js";
import { a as B, _ as g } from "./index-CWsRuimy.js";
import * as D from "react";
import { forwardRef as _, useState as q, useRef as v, useEffect as P, createElement as l } from "react";
import { $ as O } from "./index-Cp-Nu9_j.js";
import { a as A, b as E } from "./index-B5chcmfZ.js";
import { $ as K } from "./index-CX8l_p9w.js";
import { $ as L } from "./index-DaaF0TLE.js";
import { $ as H } from "./index-BbgZYt7X.js";
import { $ as I } from "./index-B--ic68G.js";
import { I as T } from "./Icon-B2GFsdMo.js";
import { c as C } from "./utils-CJ9afRe1.js";
import { c as V } from "./index-BV2fynjD.js";
const R = "Checkbox", [X, ue] = O(R), [F, W] = X(R), z = /* @__PURE__ */ _((e, r) => {
  const { __scopeCheckbox: t, name: n, checked: u, defaultChecked: c, required: b, disabled: d, value: i = "on", onCheckedChange: $, ...y } = e, [a, m] = q(null), M = B(
    r,
    (o) => m(o)
  ), h = v(!1), w = a ? !!a.closest("form") : !0, [f = !1, k] = A({
    prop: u,
    defaultProp: c,
    onChange: $
  }), S = v(f);
  return P(() => {
    const o = a == null ? void 0 : a.form;
    if (o) {
      const p = () => k(S.current);
      return o.addEventListener("reset", p), () => o.removeEventListener("reset", p);
    }
  }, [
    a,
    k
  ]), /* @__PURE__ */ l(F, {
    scope: t,
    state: f,
    disabled: d
  }, /* @__PURE__ */ l(I.button, g({
    type: "button",
    role: "checkbox",
    "aria-checked": s(f) ? "mixed" : f,
    "aria-required": b,
    "data-state": N(f),
    "data-disabled": d ? "" : void 0,
    disabled: d,
    value: i
  }, y, {
    ref: M,
    onKeyDown: E(e.onKeyDown, (o) => {
      o.key === "Enter" && o.preventDefault();
    }),
    onClick: E(e.onClick, (o) => {
      k(
        (p) => s(p) ? !0 : !p
      ), w && (h.current = o.isPropagationStopped(), h.current || o.stopPropagation());
    })
  })), w && /* @__PURE__ */ l(Q, {
    control: a,
    bubbles: !h.current,
    name: n,
    value: i,
    checked: f,
    required: b,
    disabled: d,
    style: {
      transform: "translateX(-100%)"
    }
  }));
}), G = "CheckboxIndicator", J = /* @__PURE__ */ _((e, r) => {
  const { __scopeCheckbox: t, forceMount: n, ...u } = e, c = W(G, t);
  return /* @__PURE__ */ l(H, {
    present: n || s(c.state) || c.state === !0
  }, /* @__PURE__ */ l(I.span, g({
    "data-state": N(c.state),
    "data-disabled": c.disabled ? "" : void 0
  }, u, {
    ref: r,
    style: {
      pointerEvents: "none",
      ...e.style
    }
  })));
}), Q = (e) => {
  const { control: r, checked: t, bubbles: n = !0, ...u } = e, c = v(null), b = K(t), d = L(r);
  return P(() => {
    const i = c.current, $ = window.HTMLInputElement.prototype, a = Object.getOwnPropertyDescriptor($, "checked").set;
    if (b !== t && a) {
      const m = new Event("click", {
        bubbles: n
      });
      i.indeterminate = s(t), a.call(i, s(t) ? !1 : t), i.dispatchEvent(m);
    }
  }, [
    b,
    t,
    n
  ]), /* @__PURE__ */ l("input", g({
    type: "checkbox",
    "aria-hidden": !0,
    defaultChecked: s(t) ? !1 : t
  }, u, {
    tabIndex: -1,
    ref: c,
    style: {
      ...e.style,
      ...d,
      position: "absolute",
      pointerEvents: "none",
      opacity: 0,
      margin: 0
    }
  }));
};
function s(e) {
  return e === "indeterminate";
}
function N(e) {
  return s(e) ? "indeterminate" : e ? "checked" : "unchecked";
}
const j = z, U = J, Y = V(
  "peer h-5 w-5 shrink-0 rounded border border-gray-neutral-80 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=indeterminate]:bg-gray-neutral-30 data-[state=checked]:border-primary  data-[state=checked]:text-primary-foreground",
  {
    variants: {
      size: {
        default: "h-5 w-5",
        sm: "h-4 w-4",
        lg: "h-6 w-6"
      }
    },
    defaultVariants: {
      size: "default"
    }
  }
), Z = D.forwardRef(({ className: e, size: r, ...t }, n) => /* @__PURE__ */ x.jsx(
  j,
  {
    ref: n,
    className: C(
      Y({
        size: r
      }),
      e
    ),
    ...t,
    children: /* @__PURE__ */ x.jsx(
      U,
      {
        className: C("relative -top-[1px] items-center justify-center text-current"),
        children: /* @__PURE__ */ x.jsx(
          T,
          {
            strokeWidth: 2,
            name: t.checked === "indeterminate" ? "Minus" : "Check",
            className: C("", {
              "w-4 h-4": r === "sm",
              "w-5 h-5": r === "default",
              "w-6 h-6": r === "lg"
            })
          }
        )
      }
    )
  }
));
Z.displayName = j.displayName;
export {
  Z as C
};
